.TopBar {
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: white;
}

.MenuItem {
  margin: 1rem;
}

.logoImg {
  width: 200px;
  height: 65px;
  object-fit: cover;
}

.TopBar,
.TopMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TopBar {
  width: 100%;

}

.TopMenu {
  /* margin-right: 2rem; */
  height: 65px;
  /* font-weight: bold; */
}

.MenuItem {
  margin: 0;
  padding: 0 1rem 0 1rem;
  height: 100%;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MenuItem>a {
  color: black;
}

.MenuItem:hover {
  background-color: #f7bf50;
}

.placerholder-div {
  width: 100%;
  height: 65px;
  margin: 0;
}