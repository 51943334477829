.func-btn-div {
  height: autp;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 20%;
  padding-right: 20%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  justify-content: center;
}

.func-btn-inner-div {
  display: flex;
  justify-content: center;
  margin: 1rem 0 1rem 0;
}

.func-btn {
  width: 350px;
  height: 55px;
  background-color: var(--main-color);
  color: black;
  font-size: larger;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.func-btn:hover {
  border: var(--main-color) solid;
  border-width: 3px;
  background-color: white;
}