/* .func-img-div {
  display: flex;
  justify-content: space-between;
  padding: 7rem 10% 7rem 10%;
  background-color: #e9e5da;
} */

.func-img-div {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  padding: 7rem 10% 7rem 10%;
  background-color: #e9e5da;
  cursor: pointer;
}

.func-img {
  width: 90%;
  height: 100%;
  min-height: 270px;
  color: white;
  font-size: larger;
  font-weight: bold;
  text-transform: capitalize;
  filter: blur(.5px);
}

.func-img>span {
  color: white;
  font-size: larger;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.func-img>span:hover {
  color: var(--main-color);
  font-weight: bolder;
}