.property-card {
  width: 100%;
  border: rgb(180, 180, 180) solid;
  border-width: 1px;
  overflow: hidden;
  margin: 0 1.2rem 0 1.2rem;
  cursor: pointer;
}

.property-card>div:first-child {
  margin-bottom: 1rem;
}

.property-info {
  padding-left: .75rem;
  padding-right: .7rem;
  min-height: 210px;
  /* height: 270px; */
}

.property-info>div {
  margin-bottom: .5rem;
}

.inspection-time>div {
  margin-bottom: .3rem;
}

.property-thumbnail {
  width: 100%;
  min-height: 300px;
  background-position: center;
  background-size: cover;
}

.property-title>span {
  text-transform: uppercase;
  font-size: large;
  font-weight: 700;
  margin-top: 3rem;
}

.property-add {
  display: flex;
  flex-direction: column;
}

.property-detail {
  display: flex;
  /* justify-content: space-around; */
}

.property-detail>div>span>i {
  margin-right: .3rem;
  font-size: medium;
  display: inline-flex;
  align-items: center;
}

.property-detail>div {
  margin-right: 1rem;
}

.property-status>span {
  text-transform: uppercase;
  margin-right: 1rem;
}

.inspection-time {
  font-size: medium;
}

.property-footer {
  border-top: rgb(180, 180, 180) solid;
  border-width: 1px;
  width: 100%;
  margin-bottom: 0 !important;
  padding-left: .2rem;
}

.property-footer>i {
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin-right: .5rem;
  color: var(--main-color);
  cursor: pointer;
}

/* .property-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);
  margin: 0 10% 0 10%;
  background-color: antiquewhite;
} */

.slider-list {
  width: 100%;
  padding: 55px 9% 55px 9%;
}

.BrainhubCarousel__arrows {
  background-color: var(--main-color) !important;
}