.tianyi-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding: 50px 10% 45px 10%;
  border-top: rgb(0, 0, 0) solid;
  border-width: 1px;
  background-color: var(--main-color);
  height: 300px;
}

.tianyi-footer>img {
  width: 300px;
  height: 65px;
  object-fit: cover;
}

.tyaddress>div>i {
  display: inline-flex;
  align-items: center;
  margin-right: .5rem;
}