.SearchBar {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: center;
}

.SearchInnerDiv {
  width: 100%;
  color: rgb(222, 222, 222);
  background-color: rgba(7, 7, 7, 0.5);
  padding: 20px 15px 15px 15px;
}

.search-key-word {
  width: 100%;
}

.Line-1,
.Line-2,
.Line-3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  line-height: 30px;
}

.Line-3 {
  margin-top: .81rem;
}

.Line-3>div>select {
  font-size: medium;
  text-transform: capitalize;
  height: 30px;
}

.search-drop {
  height: 55px;
  font-size: medium;
  padding-left: 5px;
  width: 100%;
}

.search-type {
  width: 175px;
}

.search-key-word {
  height: 55px;
  width: 100%;
}

.search-keyword-input {
  line-height: 100%;
  font-size: medium;
}

.search-btn {
  width: 175px;
  height: 55px;
}

.search-btn>button {
  height: 55px;
  width: 100%;
  text-align: center;
  font-size: medium;
  background-color: transparent;
  color: white;
  border: #f7bf50 solid;
  border-radius: 2px;
  border-width: 0.5;
}

.search-btn>button:hover {
  background-color: #f7bf50;
  color: black;
}

#search {
  width: 100%;
  height: 55px;
}

#surrounding {
  padding-left: 0;
  margin-left: 0;
}

#surrounding,
.suburb>span {
  cursor: pointer;
}


.SearchOption {
  cursor: pointer;
}

.SearchOption>i {
  display: inline-flex;
  align-items: center;
}

.searchKeyWord {
  width: 100%;
}

.Line-3>div,
.Line-3-Hide>div {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.Line-3-Hide {
  display: none;
}