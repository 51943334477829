.property-photo {
  /* width: calc(100%/3); */
  height: calc(100vw/4.7);
  background-position: center;
  background-size: cover;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.property-summary-bar {
  display: flex;
  justify-content: space-between;
  font-size: large;
  background-color: rgba(211, 211, 211, 0.8);
  padding: .5rem;
}

.left {
  display: flex;
}

.left>div>span {
  display: flex;
  margin-left: .7rem;
}

.left>div>span>i {
  display: inline-flex;
  align-items: center;
  margin-right: .2rem;
}

.right {
  text-align: right;
  font-size: large;
}

.right>span {
  text-transform: capitalize;
}

.property-event-footer {
  background-color: black;
  color: var(--main-color);
  padding: .3rem .5rem .3rem .5rem;
  display: flex;
  justify-content: left;
  align-items: center;
}

.property-event-footer>i {
  margin-right: .5rem;
  font-size: medium;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .property-thumb-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr)
  }
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .property-thumb-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr)
  }

  .property-photo {
    height: calc(100vw/2.5) !important;
  }
}

@media screen and (max-width:767px) {
  .property-thumb-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr)
  }

  .property-photo {
    height: calc(100vw/1.5) !important;
  }

}