.ContactForm {
  width: 100%vw;
  margin: 0 5rem 2rem 5rem;
}

.ContactForm input {
  line-height: 35px;
  font-size: large;
}

.ContactForm>p>input,
.ContactForm>p>textarea {
  width: 100%;
}

.contactBtn {
  border: none;
  background-color: var(--main-color);
  border-radius: 10px;
  font-size: large;
  padding: 25px;
  width: 200px;
}